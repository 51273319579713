<template>
  <v-dialog
    v-model="dialog"
    v-if="$store.getters['auth/isLoggedIn']"
    width="600"
  >
    <template v-slot:activator="{ on }">
      <v-btn small outlined v-on="on">
        {{ $t("pay") }}
      </v-btn>
    </template>
    <v-card id="select_token_payment">
      <v-card-title>
        {{ $t("pay_token_payment") }}
      </v-card-title>

      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col v-for="(pm, i) in payment_methods" :key="i">
            <base-border-card
              :title="$t('expenses.' + pm)"
              @card_clicked="pay(pm)"
              :disabled="
                pm === 'online' &&
                  $store.getters['auth/getSetting']('pm_stripe_id') === 0
              "
              :two_lines="$vuetify.breakpoint.mdAndUp"
            >
            </base-border-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      payment_methods: ["cash", "card"],
    };
  },
  props: ["appointment"],
  methods: {
    ...mapActions("appointments", ["generatePendingCustomer"]),
    pay(pm) {
      if (pm === "online")
        this.$router.push({
          name: "customer_payment",
          params: {
            token: this.appointment.studio.token,
            appointment_id: this.appointment.id,
          },
        });
      else {
        this.generatePendingCustomer({
          id: this.appointment.id,
          method: pm,
        }).then(() => {
          this.dialog = false;
          this.$emit("update");
        });
      }
    },
  },
};
</script>

<style lang="sass">
#select_token_payment
</style>
